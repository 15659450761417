<template>
    <div class="full-height-layout fill">
        <router-view ref="routeview"></router-view>
    </div>
</template>

<script>
export default {
    name: 'SupplierAttributeMappings',
}
</script>
